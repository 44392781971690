import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const NationalDebtEffectsPage = () => {
    const article = {
        id: 'a9cdc8c4-786a-5f96-a3dd-ec8ebb67a664',
        title: 'How the $20 Trillion National Debt Will Affect the Average American',
        slug: '/national-debt-effects/',
        date: '2018-07-25T19:24:11.000Z',
        modified: '2019-02-05T18:48:19.000Z',
        excerpt: 'The national debt may seem like a government problem, but it can eat out of your pocket in the future if you don&#8217;t plan ahead. Learn more about how it affects you.',
        featured_image: {
            source_url: '/media/national-debt-affect-average-american.jpg',
        },
        acf: {
            seo_title: '',
            robots: null,
        },
        read_time_minutes: 15,
        content: (
            <>
                <p>More than $20 trillion.</p>
                <p>
                    That&rsquo;s how big the nation&rsquo;s debt is—the highest it&rsquo;s
                    {' '}
                    <a href="/debt-throughout-history/">ever been in the history of the United States</a>
                    .
                </p>
                <p>In fact, we&rsquo;ve exceeded $20 trillion in national debt with a per capita debt of ~$60K.</p>
                <p>Most people know what having personal debt means.</p>
                <p>It means giving up that gorgeous blouse or looking away from that pair of shoes you&rsquo;ve been eyeing.</p>
                <p>Maybe it means fewer beers out with the guys or eating in instead of eating out at your favorite spot.</p>
                <p>Basically, it means you&rsquo;ve spent more than you make and you&rsquo;ve got to curb corners somehow to avoid driving yourself into a deeper, more irreparable debt.</p>
                <p>But what exactly does it mean when the nation is in debt?</p>
                <p>What is the national debt and should it even matter to you?</p>
                <p>Can that trillion dollar number ever even affect you?</p>
                <p>Unfortunately, the answer is yes.</p>
                <p>The national debt can and may impact you in your lifetime.</p>
                <p>Because even if your personal finances are in order, you have to remember that you&rsquo;re interlinked within a broader group of people.</p>
                <p>You belong to a country whose influences and problems will eventually impact you.</p>
                <p>Let&rsquo;s take a look at how this debt can impact your wallet and why you should pay attention to your personal finances but also be aware of what&rsquo;s going on around you.</p>
                <h2 id="national-debt-basics">National Debt Basics</h2>
                <h3>What does it mean when the nation is in debt?</h3>
                <p>The nation is in debt.</p>
                <p><LazyLoadImage alt="U.S. National Debt from 2012-2022" src="/media/u-s-national-debt-from-2012-2022-1.png" /></p>
                <p>That sounds alarming, but few people really know what it means.</p>
                <p>Before we get into that though, I want to talk about the difference between a debt and a deficit.</p>
                <p>Because often people confuse the meaning of these two terms or even use them interchangeably, so let&rsquo;s start there.</p>
                <p>A deficit is defined by the Merriam Webster dictionary as a &quot;deficiency in amount or quality.&quot;</p>
                <p>For our purposes, we&rsquo;re talking about money, so a deficit means a shortage of funds.</p>
                <p>A budget deficit is when the government spending equals more money than it brings in from the sources that help it generate income.</p>
                <p>The main thing that the government depends on for that &quot;income&quot; is taxes.</p>
                <p>These include things like individual, corporate, or excise taxes.</p>
                <p>When you think of it in terms of a business operation, a deficit is when you&rsquo;re spending more on business expenditures than you&rsquo;re making in revenue.</p>
                <p>For example, let&rsquo;s say you have a bakery and you&rsquo;re spending more money paying staff and buying ingredients to create your goods than you&rsquo;re actually making in sales.</p>
                <p>Then this would mean that you&rsquo;re working in a deficit.</p>
                <p>The federal or national debt, on the other hand, is simply the total accumulation of the federal government&rsquo;s budget deficits.</p>
                <p>In other words, the debt is the total money our government owes to its creditors.</p>
                <p>Going back to our bakery example, a total of all the deficits or shortages of money the business is lacking is what causes it to accumulate debt.</p>
                <p>Its debt is the amount of funds that it has to pay back to people, such as its vendors, suppliers, and even to banks.</p>
                <p>Anyone that they may have taken a loan from to continue day-to-day operations.</p>
                <h3>How is it possible for the government to spend more than it has?</h3>
                <p>Most people understand how a person can come into debt by spending more money than they have.</p>
                <p>For example, maxing out credit cards, borrowing loans, taking some money from a good friend or relative are all ways that you can end up spending more than you can actually afford.</p>
                <p>But the matter becomes a little more complicated when the government is involved, because it doesn&rsquo;t use the same resources you do to run up a debt.</p>
                <p>So how does a government get the funds to spend more than it actually has?</p>
                <p>As we talked about earlier, the government is heavily reliant on taxes to cover its spending.</p>
                <p>But when the taxes aren&rsquo;t enough to cover its payments, it has other resources it can turn to when it needs to borrow some funds.</p>
                <p>To obtain the extra money it spends, the US Treasury Department issues instruments like treasury bills (T-bills), treasury notes (T-notes), and treasury bonds (T-bonds).</p>
                <p>There are some key differences between each of these securities.</p>
                <p>The primary difference is how long they take to mature.</p>
                <p>
                    <strong>T-bills.</strong>
                    {' '}
                    These are short-term debt obligations backed by the Treasury Department that mature in less than one year.
                </p>
                <p>Typically, these are sold in denominations of $1,000, and the maximum purchase allowed is $5 million.</p>
                <p>The government often uses the money made from selling T-bills to fund things like the construction of highways and public schools.</p>
                <p>
                    <strong>T-notes.</strong>
                    {' '}
                    These are debt securities and are primarily issued for terms of 2, 3, 5 and 10 years with a fixed interest rate.
                </p>
                <p>You can get these from the government either through a competitive or non-competitive bid.</p>
                <p>A competitive bid means that investors get to determine the yield they want.</p>
                <p>The yield is the amount of return you get on an investment, expressed as a percentage rate.</p>
                <p>The caveat is that their bid may or may not get approved.</p>
                <p>With a non-competitive bid, the yield determined at the auction is what the investor accepts.</p>
                <p>
                    <strong>T-bonds.</strong>
                    {' '}
                    Like T-notes, these are also fixed-interest debt securities.
                </p>
                <p>But the difference here is that they have the longest maturity term of the three options, usually more than 10 years and closer to 30 years.</p>
                <p>T-bonds pay out interest semi-annually, and the income you make from them is taxed only at the federal level, not the state level.</p>
                <p>Bonds are known to be risk-free because they&rsquo;re issued by the government with only a very small risk of default.</p>
                <p>By selling T-bill, T-notes, and T-bonds, the federal government is able to get the money it needs to provide government services and programs that its citizens depend on.</p>
                <p>We&rsquo;ll talk more in a bit about what those services are.</p>
                <p>But for now, it&rsquo;s important to understand that the government finances its deficit or shortage of funds by selling these securities.</p>
                <p>It sells them to both domestic and foreign investors, corporations and other global governments.</p>
                <p>The money that these individuals and companies pay for treasury securities is then used to fund government activities and programs.</p>
                <h2 id="debt-distribution">Debt Distribution</h2>
                <h3>Now that you know how it&rsquo;s possible for the government to spend more than it has, you&rsquo;re probably wondering exactly where did it spend all those funds</h3>
                <p>After all, $20 trillion is no small amount.</p>
                <p><LazyLoadImage alt="Holders of U.S. Government Debt" src="/media/holders-of-u-s-government-debt-2.png" /></p>
                <p>Think of the debt as being broken down in thirds.</p>
                <p>Two-thirds of that debt is held by the public through money the government owes to buyers of the T-bills, T-notes, and T-bonds we talked about earlier.</p>
                <p>The remaining one third is money the US government owes itself.</p>
                <p>This is known as intragovernmental debt.</p>
                <p>The money for this kind of debt is owed to the US departments that hold government account securities.</p>
                <p>For instance, the Social Security Trust Fund is the government program that the government owes the greatest amount of debt to.</p>
                <p>How is that even possible?</p>
                <p>Look closely, and you&rsquo;ll see that social security has been running on surpluses for a long time.</p>
                <p>The government has optimized on those surpluses by using them to pay for other departments.</p>
                <p>That&rsquo;s why it owes money back so much money to the Social Security Trust Fund.</p>
                <p>So the next time you&rsquo;re asked who owns the US debt, you can confidently say that it&rsquo;s owned by everyone who has retirement money with the government&rsquo;s social security program.</p>
                <p>Because that&rsquo;s what the government has used to source its projects and spendings!</p>
                <p>It&rsquo;s kind of like borrowing from Peter to pay Paul.</p>
                <p>Think about what would happen if you were to do something like that with your personal finances.</p>
                <p>If you borrowed from one person to pay another, that wouldn&rsquo;t diminish your debt.</p>
                <p>It would only transfer it from one party to another.</p>
                <p>And if you keep borrowing and borrowing with no end in sight, your debt will grow exponentially.</p>
                <p>That&rsquo;s why the US national debt is where it is.</p>
                <p>In fact, it climbs an average of $3.8 billion a day.</p>
                <p>Here&rsquo;s another interesting statistic to put it into perspective: the government has to borrow about 41 cents of every dollar it spends.</p>
                <p>That&rsquo;s a big chunk of its spending!</p>
                <h2 id="debt-growth">Debt Growth</h2>
                <h3>Debt can escalate, and it can often escalate fast; that&rsquo;s what&rsquo;s happened throughout the years with the US debt</h3>
                <p>Of course, when the debt started, it was nowhere near the trillion mark.</p>
                <p>But many years of mismanagement and allowing the debt to fester and grow has brought us where we are today.</p>
                <p>So what caused it to get so out of hand?</p>
                <p>We can chalk it up to five main causes.</p>
                <p>1. The government introduced several new programs that it didn&rsquo;t have enough money to start.</p>
                <p>To make matters even worse, it implemented tax cuts to benefit its citizens.</p>
                <p>Remember, tax is where the government makes its money.</p>
                <p>But with less taxes and more spending, it created a recipe for disaster and only exacerbated the national debt.</p>
                <p>2. It borrowed from the Social Security Trust Fund.</p>
                <p>This isn&rsquo;t anything new.</p>
                <p>
                    In fact,
                    {' '}
                    <a href="/us-debt-presidents/">many presidents have borrowed</a>
                    {' '}
                    from Social Security, including Roosevelt, Bush and even Obama.
                </p>
                <p>But the less money that&rsquo;s in social security, the less money the government has to work off of when Baby Boomers retire.</p>
                <p>As this generation starts hitting its retirement years even heavier, the government will have to issue them the retirement money they&rsquo;ve rightfully earned.</p>
                <p>This might mean that the government has to eventually increase taxes to help pay back into the social security fund.</p>
                <p>3. It borrowed from China and Japan.</p>
                <p>In essence, China and Japan have bought the US debt by investing in our treasuries.</p>
                <p><LazyLoadImage alt="2018 Major Foreign Holders of U.S. Treasury Securities" src="/media/2018-major-foreign-holders-of-u-s-treasury-securities-3.png" /></p>
                <p>The more they put money in our treasuries, the more they weaken their own currencies against ours.</p>
                <p>This made it cheaper and more appealing for the US to buy from them, keeping these countries profitable.</p>
                <p>This serves as a &quot;win-win&quot; for everyone involved. Except that it also increases our debt.</p>
                <p>4. Low tax rates have contributed to our debt, because low tax rates equal low tax collections.</p>
                <p>And as we know, low taxes means less money for the government to use.</p>
                <p>5. Congress keeps raising the debt ceiling, making it higher and higher and allowing for more and more debt.</p>
                <p>The debt ceiling is the highest that Congress allows the debt to be.</p>
                <p>As you might imagine, our trillions of dollars of debt didn&rsquo;t happen overnight.</p>
                <p>The nation&rsquo;s debt isn&rsquo;t new news by any means, but then why or how could any of this come to affect us as taxpayers and citizens?</p>
                <p>Let&rsquo;s talk about that now.</p>
                <h2 id="impacts">Impacts</h2>
                <h3>How does the nation&rsquo;s debt affect me?</h3>
                <p>For a long time, many of us have seen the nation operate under debt.</p>
                <p>So for most of us, the nation&rsquo;s debt is no greater than a number we occasionally toss around at each other and discuss on a whim.</p>
                <p>Because we haven&rsquo;t really seen the effects of this debt. Yet.</p>
                <p>But that doesn&rsquo;t mean we&rsquo;ll be safe from its impact forever.</p>
                <p>Say for example the people who hold the nation&rsquo;s debt suddenly ask for a larger interest on the debt they hold, or they ask for the debt to be repaid.</p>
                <p>Then what?</p>
                <p>Like I said, that hasn&rsquo;t happened yet, which is why many Americans aren&rsquo;t privy to the effects that kind of a situation can have on us as consumers.</p>
                <p>But if it ever were to happen, it would force the government to act. And act fast.</p>
                <p>And when it does is when we&rsquo;ll all see the impacts.</p>
                <p>If, or when, this situation does arise, is when we&rsquo;ll see several impacts to our day-to-day.</p>
                <p>First, you&rsquo;ll probably notice higher interest rates.</p>
                <p>When the debt continues to rise, that means that investors and foreign governments will be less inclined to purchase Treasury bonds, causing interest rates to go up.</p>
                <p>When interest rates rise, that means you&rsquo;ll end up paying higher interest for your home, credit cards, auto and all other lending products.</p>
                <p>Second, you might see a weaker job market.</p>
                <p>When companies are faced with an increase in costs such as taxes, interest rates, etc., they won&rsquo;t be as likely to hire.</p>
                <p>In fact, they&rsquo;ll be more inclined to layoff and cut back on jobs.</p>
                <p>They&rsquo;ll do anything to tighten up their purse strings, including cutting the salaries of even the highest paid executives of large corporations.</p>
                <p>No one is safe from these cuts when the financial landscape starts looking bleak.</p>
                <p>But when companies start cutting back like this, it means a weaker job market and fiercer competition for the good jobs out there.</p>
                <p>You might want to think twice before quitting your current job, regardless of whether you love that job or not.</p>
                <p>Third, you&rsquo;ll see an increase in taxes.</p>
                <p>The government cannot sustain the debt when there aren&rsquo;t enough taxes coming in.</p>
                <p>To manage the debt, therefore, the government will need to increase how much it takes in in taxes so that the nation can cover this debt.</p>
                <p>The only other alternative to increasing taxes would be for the government to reduce spending drastically.</p>
                <p>But historically, that&rsquo;s proven difficult to do.</p>
                <p>If the government is forced to cut back on spending, that means it will have to reduce benefits and programs.</p>
                <p>This will affect programs like Social Security, Medicare, and student financial aid, which will have to be significantly reduced.</p>
                <p>Based on what has happened in the past, it&rsquo;s more probable that taxes will be increased, which means everyone will be forced to pay higher taxes, eventually.</p>
                <p>Fourth, you&rsquo;ll see a weaker dollar.</p>
                <p>When the government is eager to pay off its debt, it&rsquo;ll drop the price of the dollar so it can pay back its debt in cheaper dollars.</p>
                <p>A weak dollar equals inflated prices, which is bad for consumers because you&rsquo;ll have to pay more for things, especially for imported ones.</p>
                <p>This means you&rsquo;ll see everything from your grocery bills to gas prices spike tremendously.</p>
                <p>But even after knowing all this, you might still be wondering what this has to do with you today.</p>
                <p>What&rsquo;s the benefit of knowing any of this for you today? How does it affect your life?</p>
                <p>The answer to that is this: it arms you with the knowledge to prepare for the future and what may be just around the corner.</p>
                <p>After all, planning ahead is the key to any kind of success, including your financial success.</p>
                <h2 id="planning-ahead">Planning Ahead</h2>
                <h3>How you can prepare for the impacts of the national debt</h3>
                <p>While there&rsquo;s not a whole lot you can do to change the way the government manages its finances and debt, there is something you can do to prepare for the future.</p>
                <p>You should prepare for when the nation&rsquo;s debt ends up impacting the economy—and your very own pocket.</p>
                <p>First, do a better job of managing your own finances.</p>
                <p>Being buried in a mountain of debt yourself when the nation is trying to get out of its own is definitely not a good idea—and it&rsquo;s not a good place to be, period.</p>
                <p>If you&rsquo;re in debt and you&rsquo;re not even making any headway toward paying back that debt when the government tries to get rid of its own, it&rsquo;ll be an uphill battle with everything working against you.</p>
                <p>What little money you may be saving toward paying off your debt may end up getting swallowed up by inflation, rising costs, and higher interest rates.</p>
                <p>And to survive, you could end up having to borrow more money, and take on more debt, as a result of this.</p>
                <p>That will only exacerbate your situation.</p>
                <p>
                    If you&rsquo;re already headed in a direction where you will be in debt or are in debt, act today to start turning your situation around and
                    {' '}
                    <a href="https://www.debtconsolidation.com/4-keys-out-of-debt/">digging yourself out of debt</a>
                    .
                </p>
                <p>
                    <a href="/budgeting/">Take basic budgeting</a>
                    {' '}
                    courses or read a book on personal finance to understand basic finance.
                </p>
                <p>You might think it&rsquo;s easy for me to say all of this. But I&rsquo;m also speaking from personal experience.</p>
                <p>When I was younger, my personal finances, including all of my student loan debt, was completely out of control.</p>
                <p>Although they teach a lot of subjects in high school, no one&rsquo;s teaching you the ins and outs of being financially responsible.</p>
                <p>There were no discussions at our dinner table with my parents because they were both working more than one job every day to support my brothers and me.</p>
                <p>But eventually I learned how to manage my debt, and you should too.</p>
                <p>It&rsquo;s not as difficult once you get started.</p>
                <p>Understanding how to manage your budget and avoid overspending will not only help you get out of the hole.</p>
                <p>It&rsquo;ll also be extremely helpful to you later on in life as you seek solid financial footing.</p>
                <p>Second, prepare for a higher cost of living in the future by tightening purse strings and only buying things you truly need.</p>
                <p>While it&rsquo;s okay to indulge every once in a while, don&rsquo;t go overboard and make a habit of splurging on things you could probably do without.</p>
                <p>Third, make sure you have enough cash on the side for a rainy day.</p>
                <p>Savings is an important financial strategy for anyone.</p>
                <p><LazyLoadImage alt="Survey: What&rsquo;s Keeping People From Saving?" src="/media/survey-whats-keeping-people-from-saving-4.png" /></p>
                <p>But be particularly practical about saving for the future now that you know there may be a time where your income (or lack thereof) may not be able to keep up with the inflated cost of goods.</p>
                <p>Plan ahead for large future expenses.</p>
                <p>If you&rsquo;re looking to buy a home or send your kids to college some time in the future, make sure you plan and prepare starting today.</p>
                <p>Save up what you need and make sure you don&rsquo;t frivolously squander those funds on other things.</p>
                <p>Plan and live out those plans with an eye on the future.</p>
                <p>Give retirement a good thought and map out a plan so you can save and retire comfortably when that time comes.</p>
                <p>It would be disheartening to edge close to retirement only to discover that the financial landscape has changed, and you no longer have enough to meet your retirement dreams.</p>
                <p>To make sure you&rsquo;re on the right track, it&rsquo;s a good idea to work with a financial advisor to come up with a solid financial plan.</p>
                <p>Even if retirement seems like a distant thought today.</p>
                <p>Steer clear of variable-rate credit cards, loans, mortgages, etc.</p>
                <p>These are instruments whose rates can fluctuate, meaning the amount you pay for interest can fluctuate.</p>
                <p>You don&rsquo;t want to be stuck with a varying rate during uncertain times.</p>
                <p>Opt for fixed rate loan options instead to remove the guesswork so you have a solid and definite idea of how much you&rsquo;ll need to put toward your payments each month.</p>
                <p>Plan for the unexpected.</p>
                <p>People spend most comfortably when they&rsquo;re doing well and feel financially secure.</p>
                <p>
                    In fact, that&rsquo;s partially the reason why the US has a $1 trillion
                    {' '}
                    <a href="/credit-card-debt/">credit card debt</a>
                    .
                </p>
                <p>But unexpected things can happen that can suddenly change your financial outlook.</p>
                <p>For example, in a drowning economy, you might lose your job, or your business could tank.</p>
                <p>It&rsquo;s impossible to be fully prepared for anything, but having a backup plan for most things that could go downhill or sour, like a financial crisis, is never a bad idea.</p>
                <h2 id="keeping-an-eye-on-the-future">Keeping an eye on the future</h2>
                <h3>No one can fully predict what the future holds, but what we do know is the national debt can impact your wallet</h3>
                <p>That&rsquo;s because when the government is in debt, it has to borrow money from other sources.</p>
                <p>This includes investors and countries, like China and Japan, and also from government programs like Social Security.</p>
                <p>If it comes time to pay back our debt, that means the government has to find a way to come up with that money.</p>
                <p>Meaning it will increase taxes to get those dollars out of taxpayer&rsquo;s pockets.</p>
                <p>When and if that does happen, consumers will have to pay higher taxes, face a weaker job market, see higher interest rates and beat inflation.</p>
                <p>These are tough changes to face if your personal financial situation is already in a dire state.</p>
                <p>The best thing to do to protect yourself from the future effects of the national debt is to plan and save from now.</p>
                <p>Spend cautiously and take control of your own finances to be prepared for future changes.</p>
                <p>Learn how to budget and understand the basics of cash flow so that you can manage your money wisely.</p>
                <p>Plan for retirement and other future expenses in advance to beat inflation.</p>
                <p>Be better prepared for an increase in the cost of living that may result from the effects of the national debt by saving and making sure you have enough for a rainy day.</p>
                <p>Remember, the best way to safeguard your future is to take action and maximize what you can do today.</p>
                <p>What do you think the government should do to better manage the nation&rsquo;s ballooning debt? Let us know in the comments below!</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default NationalDebtEffectsPage;
